// 下载文件流（responseType: 'blob'）
export const downloadFileStream = (fileBlob, fileName) => {
  let data = new Blob([fileBlob], { type: "text/plain;charset=UTF-8" });
  let downloadUrl = window.URL.createObjectURL(data);
  let anchor = document.createElement("a");
  anchor.href = downloadUrl;
  anchor.download = fileName;
  if (myBrowser() === "IE") {
    navigator.msSaveBlob(data, fileName);
  } else {
    let event = new MouseEvent("click");
    let eleLink = document.createElement("a");
    eleLink.download = fileName;
    eleLink.href = URL.createObjectURL(data);
    eleLink.dispatchEvent(event);
  }
  window.URL.revokeObjectURL(data);
};

// 下载功能：type为1： 下载文件（图片或其他文件）ps:同域名图片(非要设置跨域)
// type为2： base64码下载图片
export const download = (type, data, name) => {
  if (type === 1) {
    let url = data;
    let ext = url.slice(url.lastIndexOf(".") + 1).toLowerCase();
    if (isImage(ext)) {
      convertUrlToBase64(url).then(function(base64) {
        let blob = convertBase64UrlToBlob(base64);
        if (myBrowser() === "IE") {
          window.navigator.msSaveBlob(blob, name + ".jpg");
        } else {
          let event = new MouseEvent("click");
          let eleLink = document.createElement("a");
          eleLink.download = name;
          eleLink.href = URL.createObjectURL(blob);
          eleLink.dispatchEvent(event);
        }
      });
    } else {
      let eleLink = document.createElement("a");
      eleLink.download = name;
      eleLink.style.display = "none";
      eleLink.href = url;
      document.body.appendChild(eleLink);
      if (myBrowser() === "IE") {
        eleLink.click();
      } else {
        let event = new MouseEvent("click");
        eleLink.dispatchEvent(event);
      }
      document.body.removeChild(eleLink);
    }
  } else {
    let dataURL = data;
    let base64 = {
      dataURL: dataURL,
      type: "image/jpg",
      ext: "jpg",
    };
    let blob = convertBase64UrlToBlob(base64);
    if (myBrowser() === "IE") {
      window.navigator.msSaveBlob(blob, name + ".jpg");
    } else {
      let a = document.createElement("a");
      let event = new MouseEvent("click");
      a.download = name;
      a.href = URL.createObjectURL(blob);
      a.dispatchEvent(event);
    }
  }
};

// 将 base64 转换位 blob, 对象blob 存储 2进制对象的容器
export const convertBase64UrlToBlob = (base64) => {
  let parts = base64.dataURL.split(";base64,");
  let contentType = parts[0].split(":")[1];
  let raw = window.atob(parts[1]);
  let rawLength = raw.length;
  let uInt8Array = new Uint8Array(rawLength);
  for (let i = 0; i < rawLength; i++) {
    uInt8Array[i] = raw.charCodeAt(i);
  }
  return new Blob([uInt8Array], { type: contentType });
};

// 将图片地址转换为 base64 格式
export const convertUrlToBase64 = (url) => {
  return new Promise(function(resolve, reject) {
    let img = new Image();
    img.setAttribute("crossOrigin", "anonymous");
    img.onload = function() {
      let canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      let ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, img.width, img.height);
      let ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase();
      let dataURL = canvas.toDataURL("image/" + ext);
      let base64 = {
        dataURL: dataURL,
        type: "image/" + ext,
        ext: ext,
      };
      resolve(base64);
    };
    img.src = url;
  });
};

// 判断浏览器类型
export const myBrowser = () => {
  let userAgent = navigator.userAgent;
  if (userAgent.indexOf("OPR") > -1) {
    return "Opera";
  }
  if (userAgent.indexOf("Firefox") > -1) {
    return "FF";
  }
  if (userAgent.indexOf("Trident") > -1) {
    return "IE";
  }
  if (userAgent.indexOf("Edge") > -1) {
    return "Edge";
  }
  if (userAgent.indexOf("Chrome") > -1) {
    return "Chrome";
  }
  if (userAgent.indexOf("Safari") > -1) {
    return "Safari";
  }
};

// 判断文件是否为图片类型
export const isImage = (ext) => {
  if (
    ext === "png" ||
    ext === "jpg" ||
    ext === "jpeg" ||
    ext === "gif" ||
    ext === "bmp"
  ) {
    return true;
  }
};
export function downloadFile(basePath, opt) {
  // console.log(opt);
  // alert(1);
  const tmp = {
    localType: "local",
    localAddress: "",
    username: "",
    password: "",
    filePath: "uploadfiles/files",
    saveName: "",
    fileName: "",
    fileType: "",
    pathType: "relative",
    encode: "UTF-8",
  };
  tmp = Object.assign(tmp, opt);
  const url =
    basePath +
    "fileDownloadServlet?filepath=" +
    tmp.filePath +
    "&saveName=" +
    tmp.saveName +
    "&filename=" +
    tmp.fileName +
    "&fileType=" +
    tmp.fileType +
    "&pathType=" +
    tmp.pathType +
    "&localType=" +
    tmp.localType +
    "&localAddress=" +
    tmp.localAddress +
    "&username=" +
    tmp.username +
    "&password=" +
    tmp.password +
    "&encode=" +
    tmp.encode;
  const oForm = document.createElement("form");
  oForm.id = "testid";
  oForm.method = "post";
  oForm.action = url;
  oForm.target = "_self";
  document.body.appendChild(oForm);
  oForm.submit();
}
